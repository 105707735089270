import React from 'react';
import BannerDefault from '../../components/Banner/BannerDefault';
import About from './sections/AboutSection';
import Process from './sections/ProcessSection';
import Project from '../../components/Project';
import Testimonial from './sections/TestimonialSection';
import Brand from '../../components/Common/Brand/index'
import CallUs from './sections/CallUsSection';
import ScrollToTop from '../../components/Common/ScrollTop';

const HomeContent = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerDefault />
			{/* banner-start */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}

			{/* process-area-start */}
			<Process />
			{/* process-area-end */}

			{/* project-area-start */}
			<Project />
			{/* project-area-end */}

			{/* Testimonial-area-start */}
			<Testimonial />
			{/* Testimonial-area-end */}
			
			{/* Testimonial-area-start */}
			<Brand />
			{/* Testimonial-area-end */}

			{/* call us section start */}
			<CallUs />
			{/* call us section end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeContent;